import React, { useState } from "react"
import PropTypes from "prop-types"
import withLocation from "./withLocation"
import Listing from "./listing"
import SearchForm from "./search/search"
import { navigate } from "gatsby"
import MonthlyOfficeSpaceLocationSwitch from "../components/monthlyofficespacelocationswitch"
import OfficeSpaceLocationSwitch from "../components/officespacelocationswitch"
import MeetingLocationSwitch from "../components/meetinglocationswitch"
const SearchResultComponent = ({ search }) => {
  const { city } = search
  const { lat } = search
  const { lng } = search
  const { spaceType } = search

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <SearchForm placeholder={city} spacetype="dailyofficeSpace" />
            <br></br>
            {spaceType === "dailyofficeSpace" ? (
              <OfficeSpaceLocationSwitch />
            ) : (
              ""
            )}
            {spaceType === "monthlyofficeSpace" ? (
              <MonthlyOfficeSpaceLocationSwitch />
            ) : (
              ""
            )}
            {spaceType === "meetingSpace" ? <MeetingLocationSwitch /> : ""}
            <div className="padding-20"></div>
          </div>

          <div className="col-md-12">
            <div className="filterbar" style={{ marginTop: 15 }}>
              <ul className="SearchListingFilter scrollmenu">
                <li>
                  <a
                    onClick={e => {
                      var url =
                        "/search/?city=" +
                        city +
                        "&lat=" +
                        lat +
                        "&lng=" +
                        lng +
                        "&spaceType=dailyofficeSpace"
                      navigate(url)
                    }}
                    className={spaceType === "dailyofficeSpace" ? "active" : ""}
                  >
                    Coworking Spaces
                  </a>
                </li>
                <li>
                  <a
                    onClick={e => {
                      var url =
                        "/search/?city=" +
                        city +
                        "&lat=" +
                        lat +
                        "&lng=" +
                        lng +
                        "&spaceType=meetingSpace"
                      navigate(url)
                    }}
                    className={spaceType == "meetingSpace" ? "active" : ""}
                  >
                    Meeting Spaces
                  </a>
                </li>
                <li>
                  <a
                    onClick={e => {
                      var url =
                        "/search/?city=" +
                        city +
                        "&lat=" +
                        lat +
                        "&lng=" +
                        lng +
                        "&spaceType=monthlyofficeSpace"
                      navigate(url)
                    }}
                    className={
                      spaceType === "monthlyofficeSpace" ? "active" : ""
                    }
                  >
                    Office Spaces
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {city !== "" &&
      lat !== "" &&
      lng !== "" &&
      spaceType === "dailyofficeSpace" ? (
        <Listing city={city} lat={lat} lng={lng} spacetype="dailyofficeSpace" />
      ) : (
        ""
      )}
      {city !== "" &&
      lat !== "" &&
      lng !== "" &&
      spaceType === "monthlyofficeSpace" ? (
        <Listing
          city={city}
          lat={lat}
          lng={lng}
          spacetype="monthlyofficeSpace"
        />
      ) : (
        ""
      )}
      {city !== "" &&
      lat !== "" &&
      lng !== "" &&
      spaceType === "meetingSpace" ? (
        <Listing city={city} lat={lat} lng={lng} spacetype="meetingSpace" />
      ) : (
        ""
      )}
    </div>
  )
}

SearchResultComponent.propTypes = {
  search: PropTypes.object,
}

export default withLocation(SearchResultComponent)
