import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const MeetingLocationSwitch = () => {
  const data = useStaticQuery(graphql`
    query MeetingLocationMenu {
      allCities {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
    }
  `)
  return (
    <div className="scrollmenu">
      {data.allCities.edges.map(datas => {
        return (
          <Link
            className="ListingSearchLink"
            to={"/meeting-spaces/" + datas.node.slug + "/all"}
          >
            {datas.node.displayCity}
          </Link>
        )
      })}
    </div>
  )
}
export default MeetingLocationSwitch
